<template>
  <div class="manage-story">
    <Wait/>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Wait from "@/components/Wait";

function Component(initialize) {
  this.name = "pageManageStory";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Wait},
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({});

    return {component, state};
  },
});
</script>

<style lang="scss" scoped>
.manage-story {
}
</style>